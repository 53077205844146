import {Breadcrumb, Typography, Col, Row, Image} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import axiosInstance from "../../services/axios";

const {Title, Paragraph, Text} = Typography;

const DamageEvents = (props) => {

    useEffect(() => {
        const payload = {page_name: 'help_center_damage_events'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    return (<>
        <Title style={{margin: 0, marginBottom: 24}} level={1}> Damage Events </Title>
        <Row style={{gap: 16, maxWidth: 1200, marginBottom: 40}}>
            <Text>
                The carvaloo AI identifies all anomalies associated with the vehicle during standstill and driving. Anomalies are defined
                as unusual movement or driving patterns. The carvaloo AI is able to differentiate between anomalies such as
                cobblestones, closing doors, harsh driving maneuvers and those that lead to damage to the vehicle.
            </Text>
            <Image
                preview={false}
                width={'100%'}
                // style={{maxWidth: 1200}}
                src={require("../../assets/images/help_center/damage_events_pattern.png")}
            />
            <Text>
                Each identified damage is referred to as a damage event. We differentiate between proactively identified
                damage events and reactively identified damage events.
            </Text>
            <Text>
                For <b>proactive damage events</b>, we send out damage notification alerts automatically via e-mail and/or API after our AI detected a damage
                pattern. The new event will appear in the carvaloo WebApp as an open case awaiting for inspection feedback.
            </Text>
            <Text>
                For <b>reactive damage events</b>, an existing vehicle damage that we haven't reported
                is allocated to the exact time and context of the causal damage event retrospectively.
                In that case, it is possible to create a damage case that will open a support ticket on the carvaloo WebApp.
                The damage to the car will then be assigned to the event in which the damage occurred.
            </Text>
            <Text>
                The following sub-sections explain how to interpret the damage report, edit damage events and create reactive cases.
            </Text>
        </Row>
        <Title id="reports" style={{margin: 0, marginBottom: 15}} level={2}> Event Report </Title>
        <Row style={{gap: 40, maxWidth: 1200, marginBottom: 15}}>
            <Text>
                The event report contains all information about the damage event, such as the complete route, the damage location or the predicted damage position on the car.
                The report is linked in every damage notification by email and can also be accessed via the event page in the WebApp.
            </Text>
        </Row>
        <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> Map </Title></Row>
                <Row flex="auto">
                    <Text>
                        The map shows the route around the time of the event, displayed with GPS positions.<br/>
                        <ul>
                            <li>red dot = event position,</li>
                            <li>blue dots = GPS positions several minutes around the event (at the distance of 15 sec.),</li>
                            <li>yellow dots = GPS positions in th immediate 2 minutes around the event (at the distance of ca. 2 sec.),</li>
                            <li> green dots = GPS position of door activities.</li>
                        </ul>
                        More details such as timestamp, speed and address appear on mouse hover over the GPS positions.<br/>
                        The route can be retraced as an animation by pressing the play button at the bottom left.
                        Google Street View can be activated by placing the yellow person icon on the map.
                        This can help you to get a better overview of the surrounding event location and identify potential collision objects.
                    </Text>
                </Row>
            </Col>
            <Col flex="400px">
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reports_map.png")}
                    />
            </Col>
        </Row>
        <Row align="middle" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col flex="400px">
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reports_plot.png")}
                    />
            </Col>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> Data Plot </Title></Row>
                <Row flex="auto">
                    <Text>
                        The detailed data plot image shows the time-series visualisation of acceleration and gyro data as well as the speed including the speed limit (red sector) and the exact event time (yellow marker).
                        <br/>
                        The vehicle top view visualizes the detected damage direction, with the red colored funnel representing the predicted impact direction, thus also the predicted most likely damage position.
                        Secondary impacts are shown in gray, which can also be collisions or swingback effects. In some events a red arrow is visible which indicates the possibility of grazing damages.
                        Since the predictions are based on likelihoods, we recommend to always inspect the entire vehicle.
                    </Text>
                </Row>
            </Col>
        </Row>
        <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> Damage context information </Title></Row>
                <Row flex="auto">
                    <Text>
                    The tables below provide background information on vehicle activity (trips, standstill) around the time of the event.
                    Door activity is particularly interesting as it can be a predictor for a potential damage.
                    It is common for a driver to inspect the car after a collision and therefore make an unplanned stop.
                    </Text>
                </Row>
            </Col>
            <Col flex="400px">
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reports_tables.png")}
                    />
            </Col>
        </Row>
        <Title id="feedback" style={{margin: 0, marginBottom: 15}} level={2}> Event Feedback </Title>
        <Col style={{maxWidth: 1200, marginBottom: 15}}>
            <Row style={{marginBottom: 40}}>
                <Text>
                    Every damage pattern that was detected by our AI is referred to as a damage event.
                    Those proactively reported events require feedback by the fleet operator, technician or customer in order to be classified as a confirmed damage.
                    The feedback can be given by the fleet operator through our WebApp or by a technician or customer through the feedback link in the FeedbackApp.
                    The following describes how to provide feedback via the WebApp. The explanation on how to provide feedback via the smartphone is described in the
                    <a href={'#feedback_app'} onClick={() => props.setActiveTab('mobile_apps')} style={{marginLeft: 4, marginRight: 4}}>
                        FeedbackApp
                    </a>
                    section.
                </Text>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 1. Open the event page and select an event </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                    The event page shows all proactive damage notifications as well as the reactively created damage cases.
                    To find the right event quickly, you can use filters to speed up your search, e.g. by searching by vehicle ID or event ID.
                    By default, all events for which feedback is still pending and events with confirmed damage are displayed.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 2. Click on 'Edit' in the upper right to open the editing modal </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                    Before editing, all current event information can be viewed on the event page, such as the damage location, the severity, the date of the event notification or the event history.
                    Furthermore the event report can be accessed by clicking the button 'Event report'.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 3. Give feedback to the event  </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                    In case of a damage to the vehicle, please select <cite>'Vehicle damage detected'</cite>, upload the background information, such as the result and date of the inspection and upload pictures of the damaged vehicle.
                    Furthermore, please select all damaged car parts on the car model. Afterwards click the 'Save' button. <br/>
                    If no damage is found, select <cite>'No vehicle damage detected'</cite> and click the 'Save' button.
                    </Text>
                </Row>
            </Col>
            <Col flex="400px">
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_feedback_modal.png")}
                    />
            </Col>
            </Row>
        </Col>
        <Title id="generate_report" style={{margin: 0, marginBottom: 15}} level={2}> Generate PDF Damage Report </Title>
        <Col style={{maxWidth: 1200, marginBottom: 15}}>
            <Row style={{marginBottom: 40}}>
                <Text>
                    For each damage event (reactive and proactive), a PDF damage report can be generated. This report can be used in the communication with the customer in the claim process.
                    To generate the report, please follow the steps below:
                </Text>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 1. Open the event page and select an event </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        Open the event page, browse to find the event for which you want to generate the report and select it.
                        You can use filtering to speed up your search, such as searching by a vehicle ID or an event ID.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 2. Click on 'Export' </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        Select the Button 'Export' in the top right corner to open the PDF export window.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 3. Select the report content</Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        Select all the information you want to include in the PDF document by clicking the boxes and then click on 'Create PDF' in the lower right corner to start generating the PDF.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 4. Disable the pop-up blocker </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                    The report will open in a separate window. If it doesn't open, please disable your pop-up blocker and try again.
                    </Text>
                </Row>
            </Col>
            <Col flex="400px">
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_generate_report.png")}
                    />
            </Col>
            </Row>
        </Col>
        <Title id="reactive_event" style={{margin: 0, marginBottom: 15}} level={2}> Create Reactive Event </Title>
        <Col style={{maxWidth: 1200, marginBottom: 15}}>
            <Row style={{marginBottom: 40}}>
                <Text>
                    The carvaloo AI detects all anomalies associated with the vehicle. Most anomalies are not classified as damages and therefore not proactively reported.
                    In case a vehicle damage has been identified that we haven't reported, additional context information such as date/time and images of the damage enables the carvaloo AI to
                    reevaluate anomalies and reactively assign the anomaly most likely to have caused the damage to the event. <br/>
                    In order to trigger the reactive assignment follow the steps below:
                </Text>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col style={{width: 'calc(100% - 440px)'}}>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 1. Check the system status of the vehicle </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        To check the system status of the vehicle select 'Vehicles', insert the license plate in the filter and select the wanted vehicle.
                        In the vehicle history all past recorded events are shown. Before proceeding, please make sure that none of the events are connected to the damage.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 2. Click on 'Add Event' in the upper right corner</Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        A dialog modal will open with a brief introduction into how reactive assignment works will be shown. 
                        By clicking on the button 'Create event' you will be forwarded to three steps in which the modal assists the user to fill out the required information.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 3. Add the report information </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        <ul>
                            <li>You can insert your own IDs or use the generated ID in the format 'License Plate_YYMMDD'.</li>
                            <li>The vehicle information is prefilled. If the vehicles data shown does not match with your damaged vehicle, please close the modal and restart from <strong>step 1</strong> to select the correct vehicle.</li>
                            <li>Add when and by whom the damage was reported and click 'Next'.</li>
                        </ul>
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 4.  Add the damage context information </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        In this step the required information depends on if the damage circumstances are known or unknown. Select the option which fits to your damage case
                    </Text>
                </Row>
                <Title style={{margin: 0, marginBottom: 0, marginTop: 24}} level={5}> Damage circumstances are known </Title>
                <Row style={{marginBottom: 0}} flex="auto">
                    <Text>
                        <ul>
                            <li>Fill in when the damage happened.</li>
                            <li>Please enter the damage location by typing the address or by double clicking on the map. An address will then be automatically suggested. 
                                If this is incorrect, you may use the field as a free text field to type in the damage location (e.g. a specific highway stretch).</li>
                            <li>Describe the course of damage if it is known and proceed by clicking 'Next'.</li>
                        </ul>
                    </Text>
                </Row>
                <Title style={{margin: 0, marginBottom: 0, marginTop: 24}} level={5}> Damage circumstances are unknown </Title>
                <Row style={{marginBottom: 0}} flex="auto">
                    <Text>
                        <ul>
                            <li>Fill in the last known date without the damage. If there were any carvaloo damage alerts between this date and the damage reporting date, you will find a list of those alerts. 
                                Before proceeding with the creation of the reactive event, please view the damage alerts, as there is a high probability that they are related to the damage.</li>
                            <li>If known, you can enter the specific damage location by typing the address or double clicking on the map. Alternatively, 
                                you may use the field as a free text field to type in a more unspecific damage location, like a city district or leave the field blank.</li>
                            <li>Proceed by clicking 'Next'.</li>
                        </ul>
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 5. Input the damage information </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        Mark the damage position by clicking the car parts in the model, select the event intensity, upload pictures of the damage and insert all additional information. 
                        Thereby the damage pictures are mandatory, since a reliable matching is not possible without detailed pictures of the damaged car parts.
                    </Text>
                </Row>
                <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}> 6. Click 'Create event' to trigger the reactive assignment process. </Title></Row>
                <Row style={{marginBottom: 15}} flex="auto">
                    <Text>
                        As soon as the assignment is done, our analytics team will inform you via e-mail. The assignment usually takes about 2-3 working days.
                    </Text>
                </Row>
            </Col>
            <Col flex="400px">
            <Image style={{marginBottom: 20}}
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reactive_event1.png")}
                    />
            <Image style={{marginBottom: 20}}
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reactive_event2.png")}
                    />
            <Image style={{marginBottom: 20}}
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reactive_event3.png")}
                    />
            <Image
                    preview={true}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/damage_events_reactive_event4.png")}
                    />
            </Col>
            </Row>
        </Col>
    </>)
};

export default DamageEvents;
