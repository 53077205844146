import React, {useEffect, useState, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {
    ConfigProvider,
    Breadcrumb,
    Layout,
    Menu,
    Image,
    Form,
    Switch,
    Typography,
    Popover,
    Dropdown,
    Space,
    message,
    notification,
    Radio,
    Button,
    Row,
    Input, Col, Tabs, Card, Anchor
} from 'antd';
import TabContent from "./TabContent";
import FleetSecurity from "./FleetSecurity";

const {Title, Paragraph, Text} = Typography;
const {Search} = Input;

const ContentCard = (props) => {

    const getCurrentAnchor = () => {
        // Logic to determine the current anchor link key based on the page URL or other criteria
        // For example, you can parse the URL to get the hash part and return the corresponding key
        return props.activeTab; // key of the 'Overview' link
    };

    const handleClick = (e, link) => {
        if (props.tabs.includes(link.href)) {
            e.preventDefault();
            props.setActiveTab(link.href)
        }
    };

    let anchorItems =
        [
            {
                key: 'overview',
                href: 'overview',
                title: 'Overview',
            },
            {
                key: 'damage_events',
                href: 'damage_events',
                title: 'Damage Events',
                children: getCurrentAnchor() === 'damage_events' ? [
                    {
                        key: 'damage_events_reports',
                        href: '#reports',
                        title: 'Event Reports',
                    },
                    {
                        key: 'damage_events_feedback',
                        href: '#feedback',
                        title: 'Event Feedback',
                    },
                    {
                        key: 'damage_events_generate_report',
                        href: '#generate_report',
                        title: 'Generate Damage Report',
                    },
                    {
                        key: 'damage_events_reactive',
                        href: '#reactive_event',
                        title: 'Create Reactive Event',
                    }
                ] : [],
            },
            {
                key: 'installation',
                href: 'installation',
                title: 'Installation',
                children: getCurrentAnchor() === 'installation' ? [
                    {
                        key: 'telematics_mounting',
                        href: '#telematics_mounting',
                        title: 'Telematics Mounting',
                    },
                    {
                        key: 'box_activation',
                        href: '#box_activation',
                        title: 'Box Activation',
                    }
                ] : [],
            },
            {
                key: 'user_management',
                href: 'user_management',
                title: 'User Management',
            },
            {
                key: 'mobile_apps',
                href: 'mobile_apps',
                title: 'Mobile Apps',
                children: getCurrentAnchor() === 'mobile_apps' ? [
                    {
                        key: 'installation_app',
                        href: '#installation_app',
                        title: 'InstallationApp',
                    },
                    {
                        key: 'inspection_app',
                        href: '#inspection_app',
                        title: 'InspectionApp',
                    },
                    {
                        key: 'feedback_app',
                        href: '#feedback_app',
                        title: 'FeedbackApp',
                    },
                ] : [],
            },
            {
                key: 'vehicle_management',
                href: 'vehicle_management',
                title: 'Vehicle Management',
            }
        ]
    if (props.permissions?.security) {
        anchorItems.push(
            {
                key: 'fleet_security',
                href: 'fleet_security',
                title: 'Fleet Security',
                children: getCurrentAnchor() === 'fleet_security' ? [
                    {
                        key: 'geofencing',
                        href: '#geofencing',
                        title: 'Geofencing',
                    },
                    {
                        key: 'theft',
                        href: '#theft',
                        title: 'Theft',
                    },
                    {
                        key: 'suspicious',
                        href: '#suspicious',
                        title: 'Suspicious Activity',
                    },
                    {
                        key: 'reservation',
                        href: '#reservation',
                        title: 'Reservation',
                    },
                    {
                        key: 'manage',
                        href: '#manage',
                        title: 'Manage Alerts',
                    },
                ] : [],
            }
        )
    }

    anchorItems.push(
        {
            key: 'faq',
            href: 'faq',
            title: 'FAQ',
        }
    )

    // Return any JSX for the HomeScreen component (optional)
    return (<>
        <div style={{
            width: 'calc(100vw - 112px)',
            minHeight: 'calc(100vh - 240px)',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.20)',
            marginBottom: 24,
        }}>
            <Row style={{height: '100%', width: '100%'}} type="flex">
                <Col style={{
                    borderRadius: 8,
                    width: 220,
                    minHeight: 'calc(100vh - 240px)',
                    backgroundColor: '#f5f5f5',
                    boxShadow: '2px 0px 2px 0px rgba(0, 35, 11, 0.20)',
                    marginRight: 32,
                }}>
                    <div style={{position: 'sticky', top: 0, zIndex: 1}}>
                        <Anchor
                            style={{paddingTop: 24}}
                            getCurrentAnchor={getCurrentAnchor}
                            affix={false}
                            onClick={handleClick}
                            items={anchorItems}
                        />
                    </div>
                </Col>
                <Col style={{padding: 24, width: 'calc(100vw - 376px)'}}>
                    {props.body}
                </Col>
            </Row>
        </div>
    </>)
};

export default ContentCard;
