import React, {useEffect, useState, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {
    Layout,
    Menu,
    Image,
    Form,
    Switch,
    Popover,
    Dropdown,
    Space,
    message,
    Tooltip,
    Radio,
    Button,
    Row,
    Badge, ConfigProvider, notification
} from 'antd';

import CVSidebar from "./../../common_components/Webapp/CVSidebar";

import Icon, {QuestionCircleOutlined, QuestionOutlined} from '@ant-design/icons';
import {
    ClockCircleOutlined,
    RightOutlined,
    LeftOutlined,
    TeamOutlined,
    LogoutOutlined,
    UserOutlined,
    HomeOutlined
} from '@ant-design/icons';

import {useAuth} from "react-oidc-context";
import Carvaloo from "../../assets/logos/carvaloo-logo-webapp.png";
import Free2Move from "../../assets/logos/free2move_logo.png";
import {ReactComponent as User} from "../../assets/icons/carvaloo-user.svg";
import {ReactComponent as Settings} from "../../assets/icons/carvaloo-settings.svg";
import {ReactComponent as Incidents} from "../../assets/icons/carvaloo-incidents.svg";
import {ReactComponent as Dashboard} from "../../assets/icons/carvaloo-dashboard.svg";
import {ReactComponent as Events} from "../../assets/icons/carvaloo-events_current.svg";
import {ReactComponent as Vehicles} from "../../assets/icons/carvaloo-vehicles.svg";
import {ReactComponent as Fleet} from "../../assets/icons/carvaloo-fleet_current.svg";
import {ReactComponent as Statistics} from "../../assets/icons/carvaloo-statistics.svg";
import {ReactComponent as Inspections} from "../../assets/icons/carvaloo-inspections.svg";
import {ReactComponent as Security} from "../../assets/icons/carvaloo-security.svg";
import {ReactComponent as Tracking} from "../../assets/icons/carvaloo-tracking.svg";
import {ReactComponent as Prevention} from "../../assets/icons/carvaloo-prevention.svg";
import axiosInstance from "../../services/axios";
import {SettingsContext} from "../../App.js";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

const {Sider} = Layout;

const Sidebar = (props) => {

    const {timezone, distance, setTimezone, setDistance} = useContext(SettingsContext);
    const [selectedKey, setSelectedKey] = useState(null);
    const [organizationItems, setOrganizationItems] = useState([]);
    const navigate = useNavigate();
    const auth = useAuth();
    const [collapsed, setCollapsed] = useState(true);

    const userRoles = (auth?.user?.profile.resource_access?.incident_frontend?.roles) || []

    const currentURL = window.location.href;
    let urlBase = "https://webapp.carvaloo.com/"
    let queryParameters = ""
    if (currentURL.includes("incident-dev") || currentURL.includes("localhost")) {
        // Redirect to the development URL
        urlBase = "https://webapp-dev.carvaloo.com/";
        queryParameters = "?organization=" + props.organizationId
    }

    let urlBaseIncident = "https://incident.carvaloo.com/"
    if (currentURL.includes("incident-dev")) {
        // Redirect to the development URL
        urlBaseIncident = "https://incident-dev.carvaloo.com/";
    }
    if (currentURL.includes("localhost")) {
        // Redirect to the development URL
        urlBaseIncident = "http://localhost:3000/";
    }

    const linkWithOrganization = (site, organizationId) => {
        // Map locale to appropriate time format
        if (organizationId) {
            // English locale example: Use 12-hour format for English
            return site + "?organization=" + organizationId;
        } else {
            // For other locales, default to a 24-hour format
            return site;
        }
    };

    useEffect(() => {
        const initialUrl = window.location
        if (initialUrl.pathname.includes('fleet_tracking')) {
            setSelectedKey('tracking')
        } else if (initialUrl.pathname.includes('incidents')) {
            setSelectedKey('incidents')
        } else if (initialUrl.pathname.includes('security')) {
            setSelectedKey('security')
        } else if (initialUrl.pathname.includes('prevention')) {
            setSelectedKey('prevention')
        } else if (initialUrl.pathname.includes('help_center')) {
            setSelectedKey('help_center')
        } else {
            setSelectedKey('none')
        }

    }, []);

    useEffect(() => {
        if (props.customerMapping) {
            setOrganizationItems(
                Object.entries(props.customerMapping).map(([key, val]) => ({
                    label: key, // Assuming you want to use the key as the label
                    key: val,
                    title: "", // You may modify this as needed
                    icon: <UserOutlined/>, // You may modify this as needed
                }))
            );
        }
    }, [props.customerMapping]);

    let menuItems = []
    menuItems.push(
        {
            label: (
                <Link to={urlBase.concat("dashboard", queryParameters)}
                    // target="_blank"
                      style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                    Dashboard
                </Link>),
            title: "",
            icon: (<Icon component={Dashboard}
                         style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
            key: 'dashboard',
            style: {marginBottom: 8}
        },
    )
    if (props.permissions?.webapp_viewer) {
        menuItems.push(
            {
                label: (
                    <Link to={urlBase.concat("ereignisse", queryParameters)}
                        // target="_blank"
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                        Events
                    </Link>),
                title: "",
                icon: (<Icon component={Events}
                             style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4, color: 'white'}}/>),
                // icon: (
                //     <div style={{marginTop: collapsed ? 0 : 4}}>
                //         <Badge
                //             count={221}
                //             size="small"
                //             overflowCount={99}
                //             offset={collapsed ? [-2,5] : [0, -5]}
                //         >
                //             <Icon component={Events} style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>
                //         </Badge>
                //     </div> ),
                // icon: (
                //     <div style={{marginTop: collapsed ? 8 : 0}}>
                //         <Badge
                //             count={111}
                //             size="small"
                //             overflowCount={99}
                //             offset={collapsed ? [0, 1] : [78, -4]}
                //             style={{padding: 3, paddingTop: 0}}
                //         >
                //             <Icon component={Events} style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>
                //         </Badge>
                //     </div> ),
                key: 'events',
                style: {marginBottom: 8}
            },
        )
        menuItems.push(
            {
                className: 'custom-menu-item',
                label: (
                    <Link to={urlBase.concat("fahrzeug", queryParameters)}
                        // target="_blank"
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                        Vehicles
                    </Link>),
                title: "",
                icon: (<Icon component={Vehicles}
                             style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'vehicles',
                style: {marginBottom: 8}
            },
        )
    }

    if (props.permissions?.webapp_inspection) {
        menuItems.push(
            {
                className: 'custom-menu-item',
                label: (
                    <Link to={urlBase.concat("inspektion", queryParameters)}
                        // target="_blank"
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                        Inspection
                    </Link>),
                title: "",
                icon: (
                    <Icon component={Inspections}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'inspection',
                style: {marginBottom: 8}
            },
        )
    }

    if (props.permissions?.webapp_fleet) {
        menuItems.push(
            {
                className: 'custom-menu-item',
                label: (
                    <Link to={urlBase.concat("fleet", queryParameters)}
                        // target="_blank"
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                        Fleet
                    </Link>),
                title: "",
                icon: (<Icon component={Fleet}
                             style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4, color: 'white'}}/>),
                key: 'fleet',
                style: {marginBottom: 8}
            },
        )
    }

    if (props.permissions?.webapp_statistics) {
        menuItems.push(
            {
                className: 'custom-menu-item',
                label: (
                    <Link to={urlBase.concat("statistic", queryParameters)}
                        // target="_blank"
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>
                        Statistics
                    </Link>),
                title: "",
                icon: (
                    <Icon component={Statistics}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'statistic',
                style: {marginBottom: 8}
            },
        )
    }

    if (props.permissions?.incident) {
        menuItems.push(
            {
                label: (
                    <Link to={linkWithOrganization("/incidents", props.organizationId)}
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>Incidents
                    </Link>),
                title: "",
                icon: (
                    <Icon component={Incidents}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'incidents',
                style: {marginBottom: 8}
            },
        )
    }
    if (props.permissions?.security) {
        menuItems.push(
            {
                label: (
                    <Link to={linkWithOrganization("/fleet_security", props.organizationId)}
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>Security</Link>),
                title: "",
                icon: (
                    <Icon component={Security}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'security',
                style: {marginBottom: 8}
            },
        )
    }
    if (props.permissions?.tracking) {
        menuItems.push(
            {
                label: (
                    <Link
                        to={linkWithOrganization("/fleet_tracking", props.organizationId)}
                        style={{
                            fontSize: 14, fontWeight: 400, color: 'white'
                        }}>
                        Tracking
                    </Link>
                ),
                title: "",
                icon: (
                    <Icon component={Tracking}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'tracking',
                style: {marginBottom: 8}
            },
        )
    }
    if (props.permissions?.prevention) {
        menuItems.push(
            {
                label: (
                    <Link to={linkWithOrganization("/damage_prevention", props.organizationId)}
                          style={{fontSize: 14, fontWeight: 400, color: 'white'}}>Prevention</Link>),
                title: "",
                icon: (
                    <Icon component={Prevention}
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>),
                key: 'prevention',
                style: {marginBottom: 8}
            },
        )
    }

    const onClickOrganization = ({key}) => {
        message.info(`Changed organization to ${key}`);
        props.onOrganizationChange(key)
    };

    const onClickResetTours = (props) => {

        let request = {
            setting_value: true,
            setting_name: 'intro_tracking_tour'
        }
        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error activating tour', description: error.message});
            })

        request = {
            setting_value: true,
            setting_name: 'intro_security_tour'
        }
        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
                message.info(`Reactivating App Tours... Please refresh the page for this to take effect.`);
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error activating tour', description: error.message});
            })

        request = {
            setting_value: true,
            setting_name: 'new_feature_tracking_tour'
        }
        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
                message.info(`Reactivating App Tours... Please refresh the page for this to take effect.`);
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error activating tour', description: error.message});
            })
    };

    const onClickTimezone = ({target: {value}}) => {
        message.info(`Selected Timezone: ${value}`);
        setTimezone(value)
    };

    const onClickVelocity = ({target: {value}}) => {
        message.info(`Selected Distance: ${value}`);
        setDistance(value)
    };

    const timezoneItems = [
        {label: 'Browser', value: 'Browser'},
        {label: props.customerTimezone, value: props.customerTimezone},
        {label: 'UTC', value: 'UTC'},
    ];
    const velocityItems = [
        {label: 'kilometers', value: 'kilometers'},
        {label: 'miles', value: 'miles'},
    ];

    const helpSetting = (
        <div>
            <Link
                to={linkWithOrganization("/help_center", props.organizationId)}
                style={{color: "black", backgroundColor: 'white'}}
            >
                <Row>
                    <QuestionCircleOutlined style={{marginRight: 4}}/>
                    <Space>
                        Help Center
                    </Space>
                </Row>
            </Link>
        </div>
    );

    const organizationSetting = (
        <div>
            <div onClick={(e) => e?.stopPropagation()}>
                {(userRoles.includes('carvaloo')) &&
                    <div style={{maxHeight: '500px', overflowY: 'auto'}}>
                        <Dropdown
                            menu={{items: organizationItems, onClick: onClickOrganization}}
                            trigger={['click']}
                            style={{
                                maxHeight: 500,
                                height: 500,
                                overflow: 'auto'
                            }}
                            overlayStyle={{
                                height: 500,
                                overflow: 'auto'
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Change Organization
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                }
            </div>
        </div>
    );

    const timezoneSetting = (
        <div onClick={(e) => e?.stopPropagation()}>
            <Space style={{marginRight: 10}}>
                Timezone
            </Space>
            <Radio.Group
                size={'small'}
                options={timezoneItems}
                onChange={onClickTimezone}
                value={timezone}
                optionType="button"
                buttonStyle="solid"
            />
        </div>
    );

    const velocitySetting = (
        <div onClick={(e) => e?.stopPropagation()}>
            <Space style={{marginRight: 17}}>
                Distance
            </Space>
            <Radio.Group
                size={'small'}
                options={velocityItems}
                onChange={onClickVelocity}
                value={distance}
                optionType="button"
                buttonStyle="solid"
            />
        </div>
    );

    const tourSetting = (
        <div onClick={(e) => e?.stopPropagation()}>
            <Tooltip title={'These tours will show up on the individual app pages and can be set to "Do not show again" individually.'}>
                <a onClick={(e) => onClickResetTours()}>
                    <Space>
                        Reactivate All Tours
                    </Space>
                </a>
            </Tooltip>
        </div>
    );

    let settingsComponent = []
    // settingsComponent.push(
    //     {
    //         label: (helpSetting),
    //         key: 'help',
    //         style: {backgroundColor: 'white'}
    //     },
    // )

    if (userRoles.includes('carvaloo')) {
        settingsComponent.push(
            {
                label: (organizationSetting),
                key: 'organization'
            },
        )
    }
    settingsComponent.push(
        {
            label: (tourSetting),
            key: 'tour'
        },
    )
    settingsComponent.push(
        {
            label: (timezoneSetting),
            key: 'timezone'
        },
    )
    settingsComponent.push(
        {
            label: (velocitySetting),
            key: 'velocity'
        },
    )

    const settingsItems = [
        ...(settingsComponent && settingsComponent.length > 0 ? [{
            label: "Settings",
            icon: <Icon component={Settings}
                        style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>,
            key: 'settings_menu',
            theme: 'light',
            children: settingsComponent
        }] : []),
        {
            label: 'User',
            icon: <Icon component={User}
                        style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4}}/>,
            key: 'user',
            theme: 'light',
            children: [
                {
                    label: props.organization,
                    icon: <HomeOutlined/>,
                    key: 'organization_current',
                    disabled: true,
                },
                {
                    label: (auth.user?.profile.name),
                    icon: <UserOutlined/>,
                    key: 'user_name',
                    disabled: true,
                },
                {
                    label: "Sign out",
                    icon: <LogoutOutlined/>,
                    key: 'signout',
                }
            ],
        },
            {
                label: (
                    <Link
                        to={linkWithOrganization("/help_center", props.organizationId)}
                        style={{
                            fontSize: 14, fontWeight: 400
                        }}>
                        Help Center
                    </Link>
                ),
                title: "",
                icon: (
                    <QuestionCircleOutlined
                          style={{fontSize: '22px', verticalAlign: 'middle', marginTop: -2, marginLeft: -4, color: 'white'}}/>),
                key: 'help_center',
            },
    ]

    const handleMenuSelect = (item) => {
        setSelectedKey(item.key)
    }

    const handleUserMenuClick = (item) => {
        if (item.key === 'signout') {
            navigate("/sign_out");
        }
    }

    const handleSiderCollapse = () => {
        setCollapsed(!collapsed); // Toggle collapsed state on trigger click
    }

    return (
        <CVSidebar
            logo={props.organization === 'free2move' ? Free2Move : Carvaloo}
            selectedKey={selectedKey}
            menuItems={menuItems}
            settingsItems={settingsItems}
            onSelect={handleMenuSelect}
            onClickSettings={handleUserMenuClick}
            onCollapse={handleSiderCollapse}
            color={props.customerColor}
        />
    );
}


export default Sidebar;