import React, {useState, useEffect, useRef, createContext, useMemo} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {ConfigProvider, Layout, notification, Row} from 'antd';
import './App.css';
import {useAuth} from "react-oidc-context";
import {LicenseManager} from 'ag-grid-enterprise';

import {UnauthorizedScreen} from "./common_components/Auth/UnauthorizedScreen";
import {SignOutScreen} from "./common_components/Auth/SignOutScreen";
import {AuthCallback} from "./common_components/Auth/AuthCallback"
import {AuthError} from "./common_components/Auth/AuthError"
import {AuthorizedTemplate} from "./common_components/Auth/Auth";
import {getCVTheme} from "./common_components/AntD/CVTheme";

import Sidebar from './components/Sidebar/Sidebar'
import HomeScreen from './screens/HomeScreen'
import HelpCenterScreen from './screens/HelpCenterScreen'
import ForwardScreen from './screens/ForwardScreen'
import IncidentScreen from "./screens/IncidentScreen";
import FleetSecurityScreen from "./screens/FleetSecurityScreen";
import FleetTrackingScreen from "./screens/FleetTrackingScreen";
import MainLoader from "./components/MainLoader/MainLoader";
import axiosInstance from "./services/axios";
import {formatArrayOfObjects} from "./components/FleetSecurity/TableHelpers";
import DamagePreventionScreen from "./screens/DamagePreventionScreen";

const {Content, Footer} = Layout;
export const SettingsContext = createContext(null);

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-052057}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{carValoo_GmbH}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_February_2025}____[v3]_[01]_MTc0MDAwOTYwMDAwMA==a24454a77dce07be57510d6f02d62c20")

function App({instance}) {
    const [mapCenter, setMapCenter] = useState(null);
    const [customerMapping, setCustomerMapping] = useState(null);
    const [timezone, setTimezone] = useState('Browser');
    const [distance, setDistance] = useState('kilometers');
    const [trial, setTrial] = useState(false);
    const [limitedAccess, setLimitedAccess] = useState(false);
    const providerValue = useMemo(() => ({
        timezone, setTimezone,
        distance, setDistance,
        mapCenter, setMapCenter
    }), [timezone, distance, mapCenter]);

    const [organization, setOrganization] = useState(null);
    const [fleet, setFleet] = useState(null);

    const [organizationId, setOrganizationId] = useState(null);
    const [customerTimezone, setCustomerTimezone] = useState('locale');
    const [customerColor, setCustomerColor] = useState('#088ED7');
    const [customerDetails, setCustomerDetails] = useState(null);

    const [userSettings, setUserSettings] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const auth = useAuth();
    const isInitialMount = useRef(true);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlOrganization = urlSearchParams.get('organization');
    if (urlOrganization) {
        sessionStorage.setItem('incident_frontend_organization', urlOrganization);
    }

    const reverseMapping = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

    useEffect(() => {
        if (auth.isAuthenticated) {

            const tokenOrganizations = auth?.user?.profile.organizations
            const payload = {
                organizations: tokenOrganizations,
            };

            const fetchCustomers = async () => {
                try {
                    const response = await axiosInstance.post('/api/customers', payload);
                    setCustomerMapping(response.data.customers)
                } catch (error) {
                    console.log(error);
                    notification['error']({message: 'Error setting customer map', description: error.message});
                }
            };

            fetchCustomers();
        }
    }, [auth.isAuthenticated]);

    useEffect(() => {
        // If there's only one valid organization, set it directly
        if (auth.isAuthenticated) {
            const validOrganizations = auth?.user?.profile.customer_ids
            const storedOrganization = sessionStorage.getItem('incident_frontend_organization');

            if (validOrganizations.length === 1) {
                setOrganizationId(validOrganizations[0]);
            } else if (storedOrganization && validOrganizations.includes(storedOrganization)) {
                setOrganizationId(storedOrganization);
            }
        }
    }, [auth.isAuthenticated]);

    useEffect(() => {
        if (organizationId && customerMapping) {

            const reverseCustomerMapping = reverseMapping(customerMapping)
            setOrganization(reverseCustomerMapping[organizationId])

            if (isInitialMount.current) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.set('organization', organizationId);
                const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
                window.history.replaceState({}, document.title, newUrl);

                isInitialMount.current = false;
            } else {
                // Set the new organization in localStorage or wherever you store it
                sessionStorage.setItem('incident_frontend_organization', organizationId);

                // Update the URL with the organization as a query parameter and remove the last part of the route
                const urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.set('organization', organizationId);
                const pathName = window.location.pathname.split('/').slice(0, -1).join('/'); // Remove the last part of the route
                const newUrl = `${pathName}?${urlSearchParams.toString()}`;
                window.history.replaceState({}, document.title, newUrl);

                // Force a complete page reload
                window.location.reload();
            }
        }
    }, [organizationId, customerMapping]);

    useEffect(() => {
        if (organization) {

            const payload = {
                customer: organization,
            };

            const fetchData = async () => {
                try {
                    const response = await axiosInstance.post('/api/setup', payload);
                    setUserSettings(response.data.settings)
                    setPermissions(response.data.permissions)
                    setCustomerColor(response.data.color)
                    setMapCenter(response.data.map_center)
                    setTimezone(response.data.timezone)
                    setDistance(response.data.distance_units)
                    setCustomerTimezone(response.data.timezone)
                    setTrial(response.data.trial)
                    setLimitedAccess(response.data.access)
                } catch (error) {
                    console.log(error);
                    notification['error']({message: 'Error setting user permissions', description: error.message});
                }
            };

            fetchData();
        }
    }, [organization]);

    useEffect(() => {
        if (organizationId) {

            const payload = {
                customer_id: organizationId,
            };

            const fetchData = async () => {
                try {
                    const response = await axiosInstance.post('/api/customer/details', payload);
                    setCustomerDetails(response.data.data)
                    // console.log(response.data.data)
                } catch (error) {
                    console.log(error);
                    notification['error']({message: 'Error setting customer details', description: error.message});
                }
            };

            fetchData();
        }
    }, [organizationId]);

    switch (auth.activeNavigator) {
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    return (<>
            <Router>
                <Routes>
                    <Route path="/*" element={
                        <AuthorizedTemplate hasAnyRoles={[]}
                                            unauthorizedComponent={<UnauthorizedScreen/>}>
                            <ConfigProvider
                                theme={getCVTheme('#088ed7', customerColor)}
                            >
                                <SettingsContext.Provider value={providerValue}>
                                    <Layout style={{minHeight: '100vh'}}>
                                        <Sidebar
                                            organization={organization}
                                            organizationId={organizationId}
                                            customerDetails={customerDetails}
                                            fleet={fleet}
                                            setFleet={setFleet}
                                            permissions={permissions}
                                            onOrganizationChange={setOrganizationId}
                                            customerTimezone={customerTimezone}
                                            customerMapping={customerMapping}
                                            customerColor={customerColor}
                                        />
                                        {permissions ?
                                            <Layout className="site-layout" style={{maxHeight: "100vh"}}>
                                                <Content
                                                    style={{
                                                        // padding: '8px',
                                                        overflowY: "auto"
                                                    }}
                                                >
                                                    <Routes>
                                                        <Route path="/" element=
                                                            {<HomeScreen/>}
                                                        />
                                                        <Route path="/help_center" element=
                                                            {<HelpCenterScreen
                                                                permissions={permissions}
                                                                customer={organization}
                                                                customerId={organizationId}
                                                                userSettings={userSettings}
                                                            />}
                                                        />
                                                        <Route path="/incidents" element=
                                                            {permissions?.incident ?
                                                                <IncidentScreen
                                                                    permissions={permissions}
                                                                    customer={organization}
                                                                    customerId={organizationId}
                                                                    userSettings={userSettings}
                                                                /> :
                                                                <UnauthorizedScreen/>
                                                            }
                                                        />
                                                        <Route path="/fleet_security/*" element=
                                                            {permissions?.security ?
                                                                <FleetSecurityScreen
                                                                    permissions={permissions}
                                                                    customer={organization}
                                                                    customerId={organizationId}
                                                                    userSettings={userSettings}
                                                                    setUserSettings={setUserSettings}
                                                                    customerDetails={customerDetails}
                                                                    trial={trial}
                                                                    limitedAccess={limitedAccess}
                                                                /> :
                                                                <UnauthorizedScreen/>
                                                            }
                                                        />
                                                        <Route path="/fleet_tracking" element=
                                                            {permissions?.tracking ?
                                                                <FleetTrackingScreen
                                                                    permissions={permissions}
                                                                    customer={organization}
                                                                    customerId={organizationId}
                                                                    userSettings={userSettings}
                                                                    setUserSettings={setUserSettings}
                                                                    trial={trial}
                                                                    limitedAccess={limitedAccess}
                                                                /> :
                                                                <UnauthorizedScreen/>
                                                            }
                                                        />
                                                        <Route path="/damage_prevention" element=
                                                            {permissions?.prevention ?
                                                                <DamagePreventionScreen
                                                                    permissions={permissions}
                                                                    customer={organization}
                                                                    customerId={organizationId}
                                                                    userSettings={userSettings}
                                                                    trial={trial}
                                                                    limitedAccess={limitedAccess}
                                                                /> :
                                                                <UnauthorizedScreen/>
                                                            }
                                                        />
                                                        <Route path="/other" element={
                                                            <div style={{width: "300px"}}>
                                                            </div>
                                                        }/>
                                                    </Routes>
                                                </Content>
                                                <Footer style={{
                                                    textAlign: 'center',
                                                    padding: '8px'
                                                }}>©{new Date().getFullYear()} carvaloo®</Footer>
                                            </Layout>
                                            :
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100vh', /* Adjust the height to your preference */
                                                width: '100%', /* Ensure horizontal centering */
                                                flexDirection: 'column', /* Stack rows vertically */
                                            }}>
                                                <MainLoader/>
                                                {customerMapping && Object.keys(customerMapping).length > 1 && (
                                                    <div style={{marginTop: 24}}>
                                                        Please select a organization from the settings
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </Layout>
                                </SettingsContext.Provider>
                            </ConfigProvider>
                        </AuthorizedTemplate>
                    }/>
                    <Route path="/auth_callback" element={
                        <AuthCallback/>
                    }/>
                    <Route path="/sign_out" element={
                        <SignOutScreen/>
                    }/>
                </Routes>
            </Router>
        </>
    );
}

export default App;