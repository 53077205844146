import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table, Space, Checkbox,
    Divider, DatePicker, Tooltip, InputNumber, Statistic
} from 'antd';

import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    CopyOutlined,
    CarOutlined, CloseOutlined
} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import dayjs from "dayjs";

import timezone from 'dayjs/plugin/timezone'; // Import the timezone plugin
import utc from 'dayjs/plugin/utc';
import calendarIcon from "../../assets/icons/calendar.svg";
import numberIcon from "../../assets/icons/number.svg";
import checkIcon from "../../assets/icons/check.svg";
import compassIcon from "../../assets/icons/compass.svg"; // Import utc plugin
import {SettingsContext} from "../../App.js";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';


// Enable the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone); // Extend dayjs with the timezone plugin

const {Search} = Input;
const {Title, Paragraph, Text, Link} = Typography;
const {RangePicker} = DatePicker;


const VehicleInfo = (props) => {
    const {timezone, distance} = useContext(SettingsContext);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [reservationDates, setReservationDates] = useState([null, null]);

    useEffect(() => {
        if (reservationDates[0]) {
            const reservation_from = dayjs(new Date(reservationDates[0]).toISOString()).tz(timezone);
            const reservation_to = dayjs(new Date(reservationDates[1]).toISOString()).tz(timezone);
            props.handleDateRangeChange([reservation_from, reservation_to])
            props.handleTrackingChange(true)
        }
    }, [reservationDates, timezone]);

    useEffect(() => {
        if (props.inputSearch) {
            setLoading(true)
            getInfoValues();
            setLoading(false)
        } else {
            setData(null)
        }
    }, [props.inputSearch]);

    const handleDeselectClick = (value) => {
        props.onSearch(null)
    };

    const getInfoValues = () => {
        axiosInstance.post(
            '/api/location/vehicle_info',
            {
                search: props.inputSearch,
                customer: props.customer
            })
            .then(res => {
                setData(res.data)
                if (res.data.set_reservation) {

                    if (props.permissions?.tracking === 'vehicle-tracking_limited') {
                        // If permission is limited, allow dates only within the past week
                        const start_date = dayjs(res.data.reservation_from)
                        if (start_date.isBefore(dayjs().subtract(7, 'd'))) {
                            notification['warning']({
                                message: 'Reservation start more than 7 days in the past - missing the required permissions to display the reservation tracking data',
                            });
                        } else {
                            setReservationDates([res.data.reservation_from, res.data.reservation_to])
                        }
                    } else {
                        setReservationDates([res.data.reservation_from, res.data.reservation_to])
                    }
                }

            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get VehicleInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    const spanCol1 = 13
    const spanCol2 = 11

    const splitStringIntoChunks = (str, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < str.length; i += chunkSize) {
            chunks.push(str.substring(i, i + chunkSize)); // or use str.slice(i, i + chunkSize)
        }
        return chunks;
    };

    const wrapValueFormatter = (value) => {
        if (!value) return 'Not available';

        const chunks = splitStringIntoChunks(value, 24);

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                {chunks.map((chunk, index) => (
                    <span key={index}>{chunk}</span>
                ))}
            </div>
        );
    };

    return (<>
            <Card
                loading={loading}
                style={{
                    position: "absolute",
                    top: 24,
                    right: 16,
                    zIndex: 2,
                    width: 470,
                    height: 500,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                styles={{ body: {
                    padding: 24, paddingTop: 12
                }}}
                title={<>
                    {data?.vehicle || 'Not available'}
                </>}
            >
                <CloseOutlined
                    onClick={handleDeselectClick} // Make the icon clickable
                    style={{
                        position: 'absolute', // Position the icon absolutely within the card
                        top: -10,
                        left: -10,
                        zIndex: 3, // Ensure the icon is above other content
                        cursor: 'pointer', // Change the cursor to pointer on hover
                        fontSize: '16px', // Adjust the size of the icon
                        color: 'gray', // Set the icon color to gray
                        backgroundColor: 'white', // Set the background color to white
                        borderRadius: '50%', // Make the background a circle
                        padding: 4,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                    }}
                />
                <Row>
                    <Col span={spanCol1}>
                        <Statistic
                            title="Date of GPS position"
                            groupSeparator={''}
                            value={data?.last_signal ? `${timezoneDateString(data.last_signal, timezone)} ${timezoneTimeString(data.last_signal, timezone)}` : 'Not available'}
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                        <Statistic
                            title="Station"
                            value={data?.vehicle_station}
                            prefix={<img src={compassIcon} alt={compassIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6, whiteSpace: 'initial'}}
                            size="small"
                            formatter={wrapValueFormatter}
                        />
                    </Col>
                    <Col span={spanCol2}>
                        <Statistic
                            title="Fleet"
                            value={
                                data?.vehicle_fleet ?
                                    `${data?.vehicle_fleet}` :
                                    'Not available'
                            }
                            prefix={<CarOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                        <Statistic
                            title="Distance to station"
                            value={
                                data?.station_distance === 0 ? '0' : data?.station_distance ?
                                    convertKilometersToMiles(data.station_distance, distance) :
                                    'Not available'
                            }
                            prefix={<img src={compassIcon} alt={compassIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            suffix={distance === 'kilometers' ? 'km' : 'miles'}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>
                <Divider style={{marginBottom: 8, marginTop: 8}}></Divider>
                <Row style={{marginTop: 8}}>
                    <Col span={spanCol1}>
                        <Statistic
                            title="VIN"
                            groupSeparator={''}
                            value={data?.vin || 'Not available'}
                            prefix={<img src={numberIcon} alt={numberIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 2}}
                            size="small"
                            suffix={
                                <Button
                                    type="link"
                                    icon={<CopyOutlined style={{marginTop: -16}}/>}
                                    onClick={() => handleCopy(data?.vin)}
                                />
                            }
                        />
                        <Statistic
                            title="Vehicle ID"
                            groupSeparator={''}
                            value={data?.vehicle || 'Not available'}
                            formatter={(value) =>
                                data?.vehicle ? (
                                    <a href={`https://webapp.carvaloo.com/fahrzeug/${String(data?.vehicle)}?UVID=${String(data?.uuid)}`}
                                       target="_blank" rel="noopener noreferrer">
                                        {value}
                                    </a>
                                ) : (
                                    'Not available'
                                )
                            }
                            prefix={<img src={numberIcon} alt={numberIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 2}}
                            size="small"
                            suffix={
                                <Button
                                    type="link"
                                    icon={<CopyOutlined style={{marginTop: -16}}/>}
                                    onClick={() => handleCopy(data?.vehicle)}
                                />
                            }
                        />
                    </Col>
                    <Col span={spanCol2}>
                        <Statistic
                            title="Vehicle Model"
                            groupSeparator={''}
                            value={data?.vehicle_type}
                            prefix={<CarOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                            formatter={wrapValueFormatter}
                        />
                        <Statistic
                            title="Box ID"
                            groupSeparator={''}
                            value={
                                data?.box ?
                                    `${data?.box}` :
                                    'Not available'
                            }
                            prefix={<img src={numberIcon} alt={numberIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>
                <Divider style={{marginBottom: 8, marginTop: 8}}></Divider>
                <Row style={{marginTop: 8, marginBottom: 0}}>
                    <Col span={spanCol1}>
                        <Statistic
                            title={data?.set_reservation ? "Selected Reservation Status" : "Reservation Status"}
                            groupSeparator={''}
                            value={
                                data?.reservation_active ?
                                    `Active` :
                                    'Not active'
                            }
                            prefix={
                                data?.reservation_active ?
                                    <img src={checkIcon} alt={checkIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/> :
                                    <ExclamationCircleOutlined style={{marginRight: 4}}/>
                            }
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                        <Statistic
                            title="Reservation From"
                            groupSeparator={''}
                            value={data?.reservation_from ? timezoneDateTimeString(data?.reservation_from, timezone) : 'Not available'}
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500}}
                            size="small"
                        />
                    </Col>
                    <Col span={spanCol2}>
                        <Statistic
                            title={data?.set_reservation ? "Selected Reservation ID" : data?.reservation_active ? "Current Reservation ID" : "Last Reservation ID"}
                            groupSeparator={''}
                            value={
                                data?.reservation_id ?
                                    `${data.reservation_id.length > 24 ? data.reservation_id.slice(0, 24) + '...' : data.reservation_id}` :
                                    'Not available'
                            }
                            prefix={<img src={numberIcon} alt={numberIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 2}}
                            size="small"
                            suffix={
                                <Button
                                    type="link"
                                    icon={<CopyOutlined style={{marginTop: -16}}/>}
                                    onClick={() => handleCopy(data?.reservation_id)}
                                />
                            }
                        />
                        <Statistic
                            title="Reservation To"
                            groupSeparator={''}
                            value={data?.reservation_to ? timezoneDateTimeString(data?.reservation_to, timezone) : 'Not available'}
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500}}
                            size="small"
                        />
                    </Col>
                </Row>
                {
                    (data?.footer) &&
                    <div style={{textAlign: "left", marginTop: 8, fontSize: 12, marginRight: 10}}>
                        * outdated box firmware may result in incomplete or faulty GPS data
                    </div>
                }
            </Card>
        </>
    );
}


export default VehicleInfo;